import * as auth from '@/utils/auth'
import store from '@/store'
import { jwtDecode } from 'jwt-decode'

/**
 * Protects a route by verifying the user's access token and identity.
 *
 * @param {Object} to - The target route object.
 * @param {Object} from - The previous route object.
 * @param {Function} next - The next function to continue navigation.
 * @returns {void}
 */
export async function protectRoute(to, from, next) {
  // Get the access token from the store
  const accessToken = store.getters.getAccessToken

  store.commit('setTargetRoute', to.fullPath)

  // Check if the access token is valid
  if (accessToken) {
    try {
      const decodedToken = jwtDecode(accessToken)

      if (decodedToken.exp * 1000 > Date.now()) {
        // AccessToken is valid; proceed with auth.getUserInfo
        // Extract permissions from JWT and store them
        store.commit('setPermissions', decodedToken.permissions || [])
        // Check if the route requires specific permissions
        if (to.meta.requiredPermissions) {
          const userPermissions = store.getters.getPermissions
          const hasRequiredPermissions = to.meta.requiredPermissions.every(
            (permission) => userPermissions.includes(permission),
          )
          if (!hasRequiredPermissions) {
            return next('/dashboard')
          }
        }

        const responseGetUserInfo = await auth.getUserInfo()

        // Check if the user info is valid
        if (responseGetUserInfo.success) {
          // User's identity is verified; allow access to the protected route
          return next()
        } else {
          // User's identity could not be verified; redirect to the login page
          return next('/login')
        }
      }
    } catch (error) {
      // Handle any errors while decoding the token
      // console.error('Error while verifying user identity:', error)
    }
  }

  // AccessToken is not valid or expired; refresh it
  try {
    // Call the auth.refreshAccessToken function from auth
    const responseRefreshAccessToken = await auth.refreshAccessToken()

    if (responseRefreshAccessToken.success) {
      // Token refresh was successful; reattempt auth.getUserInfo
      const responseGetUserInfo = await auth.getUserInfo()

      if (responseGetUserInfo.success) {
        // Re-check permissions after refresh
        const refreshedToken = store.getters.getAccessToken
        const decodedRefreshedToken = jwtDecode(refreshedToken)
        store.commit('setPermissions', decodedRefreshedToken.permissions || [])

        if (to.meta.requiredPermissions) {
          const userPermissions = store.getters.getPermissions
          const hasRequiredPermissions = to.meta.requiredPermissions.every(
            (permission) => userPermissions.includes(permission),
          )

          if (!hasRequiredPermissions) {
            return next('/dashboard')
          }
        }

        return next()
      } else {
        // User's identity could not be verified; redirect to the login page
        return next('/login')
      }
    }
  } catch (error) {
    // Handle network or other errors during token refresh
    console.error('Error while refreshing access token:', error)
  }

  // If no valid access token is available, redirect to the login page
  return next('/login')
}
