<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid class="C">
      <CHeaderToggler class="ps-1 pe-3" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderText class="title">
        <h1>
          {{ this.title }}
        </h1>
      </CHeaderText>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb.vue'
import { onMounted, ref } from 'vue'
import router from '@/router'

export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
  },

  setup() {
    const title = ref()

    const getTitle = () => {
      return router.currentRoute.value.name
    }

    router.afterEach(() => {
      title.value = getTitle()
    })

    onMounted(() => {
      title.value = getTitle()
    })

    return {
      title: title,
    }
  },
  data() {
    return {
      title: router.currentRoute.value.fullPath,
    }
  },
}
</script>