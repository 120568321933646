export const signet = [
  '78.089996 78.089996',
  `
<style
   type="text/css"
   id="style250">
	.st0{fill:#FFFFFF;}
	.st1{fill:#231F20;}
</style>


<g
   id="layer2"
   style="display:none"
   transform="translate(-72.09,-55.2)"><text
     xml:space="preserve"
     style="font-style:normal;font-weight:normal;font-size:6.27071px;line-height:1.25;font-family:sans-serif;text-align:center;letter-spacing:3.11px;text-anchor:middle;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.156768"
     x="111.16322"
     y="164.73097"
     id="text26926"><tspan
       id="tspan26924"
       x="112.71822"
       y="164.73097"
       style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-family:Montserrat;-inkscape-font-specification:Montserrat;text-align:center;text-anchor:middle;stroke-width:0.156768">SOLUTIONS</tspan></text></g><g
   id="layer1"
   style="display:none"
   transform="translate(-72.09,-55.2)"><text
     xml:space="preserve"
     style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:19.6807px;line-height:1.25;font-family:Montserrat;-inkscape-font-specification:'Montserrat Bold';text-align:center;letter-spacing:4px;text-anchor:middle;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.520721"
     x="110.97754"
     y="156.15256"
     id="text1581"><tspan
       id="tspan1579"
       x="112.97754"
       y="156.15256"
       style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:19.6808px;font-family:Montserrat;-inkscape-font-specification:'Montserrat Bold';text-align:center;text-anchor:middle;stroke-width:0.520721">CHARGEBULL</tspan></text></g><polygon
   class="st1"
   points="110,100.66 114.58,78.87 110,79.87 105.42,78.87 "
   id="polygon255"
   transform="translate(-70.955002,-55.2)"
   style="fill:#e6e6e6;fill-opacity:1" /><path
   class="st1"
   d="M 76.955,19.2 53.875,0 l 7.16,21.79 -8.36,1.79 -2.09,11.34 -3.48,2.49 -2.99,8.06 7.46,-2.98 -6.57,19.8 -5.97,1.09 -5.97,-1.09 -6.570002,-19.8 7.460002,2.98 -2.99,-8.06 -3.480002,-2.49 -2.09,-11.34 -8.36,-1.79 7.16,-21.79 -23.06,19.2 1.49,15.92 15.62,4.88 -13.03,11.54 15.68,1.26 6.9,13.26 -0.5,2.98 3.680002,9.05 h 8.07 8.06 l 3.68,-9.05 -0.5,-2.98 6.9,-13.26 15.68,-1.26 L 59.835,40 75.455,35.12 Z m -42.49,51.43 c -1.04,0 -1.89,-0.85 -1.89,-1.89 0,-1.04 0.85,-1.89 1.89,-1.89 1.04,0 1.89,0.85 1.89,1.89 0,1.04 -0.84,1.89 -1.89,1.89 z m 9.16,0 c -1.04,0 -1.89,-0.85 -1.89,-1.89 0,-1.04 0.85,-1.89 1.89,-1.89 1.04,0 1.89,0.85 1.89,1.89 0,1.04 -0.85,1.89 -1.89,1.89 z"
   id="path257"
   style="fill:#d60000;fill-opacity:1" /><g
   id="g74561"
   style="display:none"
   transform="translate(-72.09,-55.2)"><polygon
     class="st1"
     points="110,79.87 105.42,78.87 110,100.66 114.58,78.87 "
     id="polygon74557"
     transform="translate(1.1349982)"
     style="fill:#cccccc" /><path
     class="st1"
     d="m 149.045,74.4 -23.08,-19.2 7.16,21.79 -8.36,1.79 -2.09,11.34 -3.48,2.49 -2.99,8.06 7.46,-2.98 -6.57,19.8 -5.97,1.09 -5.97,-1.09 -6.570002,-19.8 7.460002,2.98 -2.99,-8.06 -3.480002,-2.49 -2.09,-11.34 -8.36,-1.79 7.16,-21.79 -23.06,19.2 1.49,15.92 15.62,4.88 -13.03,11.54 15.68,1.26 6.9,13.26 -0.5,2.98 3.680002,9.05 h 8.07 8.06 l 3.68,-9.05 -0.5,-2.98 6.9,-13.26 15.68,-1.26 -13.03,-11.54 15.62,-4.88 z m -42.49,51.43 c -1.04,0 -1.89,-0.85 -1.89,-1.89 0,-1.04 0.85,-1.89 1.89,-1.89 1.04,0 1.89,0.85 1.89,1.89 0,1.04 -0.84,1.89 -1.89,1.89 z m 9.16,0 c -1.04,0 -1.89,-0.85 -1.89,-1.89 0,-1.04 0.85,-1.89 1.89,-1.89 1.04,0 1.89,0.85 1.89,1.89 0,1.04 -0.85,1.89 -1.89,1.89 z"
     id="path74559"
     style="fill:#ffffff;fill-opacity:1" /></g>
`,
]
