import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axiosInstance from './axios'
import i18n from './i18n'

import CoreuiVue from '@coreui/vue-pro'
import { CIcon } from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { all } from '@awesome.me/kit-b2537f5309/icons'

/* add icons to the library */
library.add(...all)

const app = createApp(App)

app.use(CoreuiVue)
app.use(router)
app.use(store)
app.use(i18n)

app.provide('icons', icons)

app.component('CIcon', CIcon)
app.component('font-awesome-icon', FontAwesomeIcon)

app.config.globalProperties.$axios = axiosInstance

app.mount('#app')
