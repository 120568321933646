import { createStore } from 'vuex'
import { getUserInfo } from '@/utils/auth' // Import your auth-related functions
import { jwtDecode } from 'jwt-decode'

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    theme: 'default',
    accessToken: null,
    targetRoute: '/dashboard',
    user: null,
    overriddenLocale: null,
    permissions: [], // New state for storing permissions
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleTheme(state, payload) {
      state.theme = payload.value
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    setAccessToken(state, token) {
      state.accessToken = token
    },
    setUser(state, user) {
      state.user = user
    },
    setTargetRoute(state, targetRoute) {
      state.targetRoute = targetRoute
    },
    setOverriddenLocale(state, locale) {
      state.overriddenLocale = locale
    },
    clearOverriddenLocale(state) {
      state.overriddenLocale = null
    },
    setPermissions(state, permissions) {
      // New mutation for setting permissions
      state.permissions = permissions
    },
  },
  getters: {
    getAccessToken: (state) => state.accessToken,
    getTargetRoute: (state) => state.targetRoute,
    currentLocale: (state) => state.overriddenLocale || userLocale,
    getPermissions: (state) => state.permissions, // New getter for permissions
    hasPermission: (state) => (permission) =>
      state.permissions.includes(permission), // New getter to check for a specific permission
    hasAllPermissions: (state) => (requiredPermissions) => {
      // New getter to check for multiple permissions
      return requiredPermissions.every((permission) =>
        state.permissions.includes(permission),
      )
    },
  },
  actions: {
    async logout({ commit }) {
      try {
        commit('setAccessToken', null)
        commit('setUser', null)
        commit('setPermissions', [])
      } catch (error) {
        // Handle logout error
      }
    },
    async checkUserIdentity({ commit, state }) {
      if (state.accessToken) {
        const response = await getUserInfo()
        if (response.success) {
          commit('setUser', response.result)
          commit('setPermissions', response.result.permissions || []) // Set permissions from user info
        } else {
          commit('setUser', null)
          commit('setPermissions', [])
        }
      }
    },
    async login({ commit, dispatch }, { accessToken }) {
      try {
        commit('setAccessToken', accessToken)
        const decodedToken = jwtDecode(accessToken)
        store.commit('setPermissions', decodedToken.permissions || [])
        await dispatch('checkUserIdentity') // Fetch user info and permissions after login
      } catch (error) {
        // Handle login error
      }
    },
    setLocale({ commit }, locale) {
      commit('setOverriddenLocale', locale)
    },
    clearLocale({ commit }) {
      commit('clearOverriddenLocale')
    },
  },
  modules: {},
})
