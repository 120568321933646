<template>
  <CFooter>
    <div></div>
    <div class="ms-auto">
      <span class="text-muted">
        <small> &copy; 2022-{{ new Date().getFullYear() }} Skjall</small>
      </span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>