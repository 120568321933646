import { createI18n } from 'vue-i18n'

const FALLBACK_LOCALE = 'en'

async function loadLocaleMessages(locale) {
  const context = import.meta.glob('@/i18n/locales/*.json')
  const messages = {}

  for (const path in context) {
    const match = path.match(/[\\\/]{1}locales[\\\/]{1}([a-z]{2})_{1}([a-z-]+)\.json$/)
    if (match && match[1] === locale) {
      const view = match[2]
      const module = await context[path]()
      messages[view] = module.default || module
    }
  }

  return messages
}

async function createI18nInstance() {
  const userLocale = navigator.language.split('-')[0] || FALLBACK_LOCALE
  const messages = {
    [userLocale]: await loadLocaleMessages(userLocale)
  }

  // If the user's locale isn't the fallback, load the fallback too
  if (userLocale !== FALLBACK_LOCALE) {
    messages[FALLBACK_LOCALE] = await loadLocaleMessages(FALLBACK_LOCALE)
  }

  return createI18n({
    locale: userLocale,
    fallbackLocale: FALLBACK_LOCALE,
    silentTranslationWarn: true,
    fallbackWarn: false,
    missingWarn: false,
    messages,
  })
}

const i18nInstance = await createI18nInstance()

export default i18nInstance

// Lazy-loading function for additional languages
export async function loadLanguageAsync(lang) {
  if (i18nInstance.global.availableLocales.includes(lang)) return

  const messages = await loadLocaleMessages(lang)
  i18nInstance.global.setLocaleMessage(lang, messages)
  i18nInstance.global.locale.value = lang
}