import axios from 'axios'
import { refreshAccessToken } from '@/utils/auth'
import config from '@/config'
import store from '@/store'

// Extend more headers the call under authRequired:
//
// headers: {
//   ...axios.defaults.headers,
//   "accept" : "application/json"
// },

const instance = axios.create({
  baseURL: config.backend.baseUrl,
  withCredentials: true,
})

// Add a request interceptor to handle the authRequired flag and token refresh
instance.interceptors.request.use(async (callConfig) => {
  if (callConfig.authRequired) {
    let accessToken = store.getters.getAccessToken

    if (isTokenExpired(accessToken)) {
      // Refresh the accessToken in the store using the /refresh endpoint
      await refreshAccessToken()

      // Refresh the accessToken from the store
      accessToken = store.getters.getAccessToken
    }
    // Add the refreshed or existing token to the request headers
    callConfig.headers.Authorization = `AR-JWT ${accessToken}`
  }
  callConfig.headers['Content-Type'] = 'application/json;charset=utf-8'
  callConfig.headers['accept'] = 'application/json;charset=utf-8'
  return callConfig
})

// Helper function to check if a JWT token is expired
function isTokenExpired(token) {
  if (!token) return true
  const payload = JSON.parse(atob(token.split('.')[1]))
  const expirationTime = payload.exp * 1000 // Convert to milliseconds
  return Date.now() >= expirationTime
}

export default instance
