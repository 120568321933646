import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import { protectRoute } from '@/routeGuard'
import { logout } from '@/utils/auth'
import i18n from '@/i18n'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: i18n.global.t('home.title'),
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: i18n.global.t('dashboard.title'),
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
        beforeEnter: protectRoute,
        meta: {
          requiredPermissions: [],
        },
      },
      {
        path: '/operations',
        name: i18n.global.t('operations.title'),

        meta: {
          requiredPermissions: ['displaypage_operations'],
        },
        children: [
          {
            path: '/operations/transactions',
            name: i18n.global.t('operations-transactions.title'),
            component: () => import('@/views/operations/Transactions.vue'),
            beforeEnter: protectRoute,
            meta: {
              requiredPermissions: [
                'displaypage_operations',
                'displaypage_operations-transactions',
              ],
            },
          },
        ],
      },
      {
        path: '/configuration',
        name: i18n.global.t('configuration.title'),
        meta: {
          requiredPermissions: ['displaypage_configuration'],
        },
        children: [
          {
            path: '/configuration/chargepoints',
            name: i18n.global.t('configuration-chargepoints.title'),
            component: () =>
              import(
                /* webpackChunkName: "configuration/chargepoints" */ '@/views/configuration/Chargepoints.vue'
              ),
            beforeEnter: protectRoute,
            meta: {
              requiredPermissions: [
                'displaypage_configuration',
                'displaypage_configuration-chargepoints',
              ],
            },
          },
          {
            path: '/configuration/idtoken',
            name: i18n.global.t('configuration-idtoken.title'),
            component: () =>
              import(
                /* webpackChunkName: "configuration/idtoken" */ '@/views/configuration/IdToken.vue'
              ),
            beforeEnter: protectRoute,
            meta: {
              requiredPermissions: [
                'displaypage_configuration',
                'displaypage_configuration-idtoken',
              ],
            },
          },
          {
            path: '/configuration/vehicles',
            name: i18n.global.t('configuration-vehicles.title'),
            component: () =>
              import(
                /* webpackChunkName: "configuration/vehicles" */ '@/views/configuration/Vehicles.vue'
              ),
            beforeEnter: protectRoute,
            meta: {
              requiredPermissions: [
                'displaypage_configuration',
                'displaypage_configuration-vehicles',
              ],
            },
          },
        ],
      },
      {
        path: '/administration',
        name: i18n.global.t('administration.title'),
        meta: {
          requiredPermissions: ['displaypage_administration'],
        },
        children: [
          {
            path: '/administration/users',
            name: i18n.global.t('administration-users.title'),
            component: () =>
              import(
                /* webpackChunkName: "/administration/users" */ '@/views/administration/Users.vue'
              ),
            beforeEnter: protectRoute,
            meta: {
              requiredPermissions: [
                'displaypage_administration',
                'displaypage_administration-users',
              ],
            },
          },
          {
            path: '/administration/roles',
            name: i18n.global.t('administration-roles.title'),
            component: () =>
              import(
                /* webpackChunkName: "/administration/roles" */ '@/views/administration/Roles.vue'
              ),
            beforeEnter: protectRoute,
            meta: {
              requiredPermissions: [
                'displaypage_administration',
                'displaypage_administration-roles',
              ],
            },
          },
          {
            path: '/administration/permissions',
            name: i18n.global.t('administration-permissions.title'),
            component: () =>
              import(
                /* webpackChunkName: "/administration/permissions" */ '@/views/administration/Permissions.vue'
              ),
            beforeEnter: protectRoute,
            meta: {
              requiredPermissions: [
                'displaypage_administration',
                'displaypage_administration-permissions',
              ],
            },
          },
          {
            path: '/administration/properties',
            name: i18n.global.t('administration-properties.title'),
            component: () =>
              import(
                /* webpackChunkName: "/administration/properties" */ '@/views/administration/Properties.vue'
              ),
            beforeEnter: protectRoute,
            meta: {
              requiredPermissions: [
                'displaypage_administration',
                'displaypage_administration-properties',
              ],
            },
          },
        ],
      },
      {
        path: '/settings',
        name: i18n.global.t('settings.title'),
        meta: {
          requiredPermissions: [],
        },
        children: [
          {
            path: '/settings/authentication',
            name: i18n.global.t('settings-authentication.title'),
            component: () =>
              import(
                /* webpackChunkName: "/settings/authentication" */ '@/views/settings/Authentication.vue'
              ),
            beforeEnter: protectRoute,
            meta: {
              requiredPermissions: [],
            },
          },
        ],
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '/login',
        name: 'Login',
        component: () =>
          import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
      },
      {
        path: '/logout',
        name: 'Logout',
        beforeEnter: logout,
        component: () =>
          import(/* webpackChunkName: "logout" */ '@/views/Login.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  prefetchLinks: false,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
