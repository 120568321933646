<template>
  <CSidebar position="fixed" :unfoldable="sidebarUnfoldable" :visible="sidebarVisible" @visible-change="(event) =>
    $store.commit({
      type: 'updateSidebarVisible',
      value: event,
    })
    ">
    <CSidebarBrand>
      <CIcon custom-class-name="sidebar-brand-full" :icon="logo" :height="35" />
      <CIcon custom-class-name="sidebar-brand-narrow" :icon="signet" :height="35" />
    </CSidebarBrand>
    <AppSidebarNav />
    <CSidebarToggler class="d-none d-lg-flex" @click="$store.commit('toggleUnfoldable')" />
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
import { logo } from '@/assets/brand/logo-negative'
import { signet } from '@/assets/brand/signet-negative'

export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  setup() {
    const store = useStore()

    return {
      logo,
      signet,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
}
</script>
@/assets/brand/signet