export const logo = [
  '521.88153 78.089996',
  `
  <style
  type="text/css"
  id="style250">
 .st0{fill:#FFFFFF;}
 .st1{fill:#231F20;}
</style>


<g
  id="layer2"
  style="display:none"
  transform="translate(-72.09,-70.955)"><text
    xml:space="preserve"
    style="font-style:normal;font-weight:normal;font-size:6.27071px;line-height:1.25;font-family:sans-serif;text-align:center;letter-spacing:3.11px;text-anchor:middle;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.156768"
    x="109.37844"
    y="164.73097"
    id="text26926"><tspan
      id="tspan26924"
      x="110.93344"
      y="164.73097"
      style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-family:Montserrat;-inkscape-font-specification:Montserrat;text-align:center;text-anchor:middle;stroke-width:0.156768">SOLUTIONS</tspan></text></g><g
  id="layer1"
  style="display:inline;fill:#ffffff"
  transform="translate(-72.09,-70.955)"><g
    aria-label="CHARGEBULL"
    id="text1581"
    style="font-weight:bold;font-size:56.6929px;line-height:1.25;font-family:Montserrat;-inkscape-font-specification:'Montserrat Bold';text-align:center;letter-spacing:4px;text-anchor:middle;stroke-width:0.520721"><path
      d="m 190.84456,122.21732 q 6.51969,0 10.65827,-5.15906 l 5.66929,5.83937 q -6.74646,7.59685 -15.93071,7.59685 -9.12756,0 -15.08031,-5.78267 -5.89606,-5.78268 -5.89606,-14.57008 0,-8.84409 6.00945,-14.740154 6.06614,-5.896061 14.79684,-5.896061 9.75118,0 16.32756,7.42677 l -5.49921,6.236215 q -4.19528,-5.215743 -10.4315,-5.215743 -4.98897,0 -8.56062,3.288193 -3.51496,3.23149 -3.51496,8.7874 0,5.49921 3.34488,8.84409 3.34488,3.34488 8.10708,3.34488 z"
      id="path4405" /><path
      d="m 242.74997,90.412601 h 8.8441 v 39.628339 h -8.8441 v -15.59055 h -15.76062 v 15.59055 h -8.84409 V 90.412601 h 8.84409 v 16.554329 h 15.76062 z"
      id="path4407" /><path
      d="m 293.57826,130.04094 -3.68503,-8.56063 H 273.2822 l -3.68503,8.56063 h -9.41103 L 277.3074,90.412601 h 8.56063 l 17.12126,39.628339 z m -11.9622,-27.83622 -4.98897,11.50866 h 9.92125 z"
      id="path4409" /><path
      d="m 343.72623,103.56535 q 0,9.52441 -7.54016,12.30236 l 10.03464,14.17323 h -10.88503 l -8.7874,-12.64252 h -6.12284 v 12.64252 h -8.84409 V 90.412601 h 15.02362 q 9.24094,0 13.15275,3.11811 3.96851,3.118109 3.96851,10.034639 z m -16.8378,6.17953 q 4.53544,0 6.17953,-1.47401 1.6441,-1.47402 1.6441,-4.64882 0,-3.2315 -1.70079,-4.422049 -1.70079,-1.190551 -5.95276,-1.190551 h -6.63307 v 11.73543 z"
      id="path4411" /><path
      d="m 382.53563,109.85827 h 8.90078 v 14.05984 q -5.89606,6.57637 -16.32755,6.57637 -8.73071,0 -14.68346,-5.78267 -5.89607,-5.78268 -5.89607,-14.57008 0,-8.84409 6.00945,-14.740154 6.06614,-5.896061 14.57008,-5.896061 8.56062,0 14.85354,5.612597 l -4.59213,6.633068 q -2.66456,-2.324408 -4.93228,-3.174801 -2.21102,-0.907086 -4.8189,-0.907086 -5.04567,0 -8.50393,3.458267 -3.45827,3.40157 -3.45827,9.01417 0,5.6126 3.28819,9.01417 3.34488,3.34488 7.93701,3.34488 4.59212,0 7.65354,-1.75748 z"
      id="path4413" /><path
      d="m 431.88983,90.412601 v 7.880313 H 412.1607 v 8.163776 h 17.74488 v 7.54016 H 412.1607 v 8.22047 h 20.35276 v 7.82362 H 403.31661 V 90.412601 Z"
      id="path4415" /><path
      d="M 461.74176,130.04094 H 444.39373 V 90.412601 h 15.42047 q 4.02519,0 6.91653,0.963779 2.94803,0.96378 4.42205,2.607874 2.66457,3.061417 2.66457,6.916536 0,4.64882 -3.00473,6.91653 -1.02047,0.7937 -1.41732,1.02047 -0.39685,0.17008 -1.41732,0.62363 3.68503,0.7937 5.83937,3.34488 2.21102,2.49448 2.21102,6.23622 0,4.13858 -2.83465,7.31338 -3.28818,3.68504 -11.45196,3.68504 z m -8.50394,-23.58425 h 4.19528 q 3.68504,0 5.44252,-0.7937 1.81417,-0.7937 1.81417,-3.40157 0,-2.664569 -1.6441,-3.514962 -1.64409,-0.850394 -5.5559,-0.850394 h -4.25197 z m 0,16.10079 h 6.06614 q 3.79843,0 5.66929,-0.90709 1.92756,-0.96378 1.92756,-3.68504 0,-2.77795 -2.04094,-3.62834 -1.98425,-0.90709 -6.4063,-0.90709 h -5.21575 z"
      id="path4417" /><path
      d="m 497.94329,119.83622 q 2.21102,2.72126 5.95275,2.72126 3.74173,0 5.89606,-2.72126 2.21103,-2.77796 2.21103,-7.54016 V 90.412601 h 8.84409 v 22.166929 q 0,8.61732 -4.7622,13.26613 -4.76221,4.59213 -12.18898,4.59213 -7.42677,0 -12.24567,-4.64882 -4.7622,-4.64882 -4.7622,-13.20944 V 90.412601 h 8.84409 v 21.883459 q 0,4.7622 2.21103,7.54016 z"
      id="path4419" /><path
      d="M 534.03146,130.04094 V 90.412601 h 8.84409 v 31.748029 h 16.89448 v 7.88031 z"
      id="path4421" /><path
      d="M 570.23293,130.04094 V 90.412601 h 8.84409 v 31.748029 h 16.89449 v 7.88031 z"
      id="path4423" /></g></g><polygon
  class="st1"
  points="114.58,78.87 110,79.87 105.42,78.87 110,100.66 "
  id="polygon255"
  transform="translate(-72.09,-70.955)"
  style="fill:#e6e6e6;fill-opacity:1" /><path
  class="st1"
  d="M 75.82,19.2 52.74,0 l 7.16,21.79 -8.36,1.79 -2.09,11.34 -3.48,2.49 -2.99,8.06 7.46,-2.98 -6.57,19.8 -5.97,1.09 -5.97,-1.09 -6.57,-19.8 7.46,2.98 L 29.83,37.41 26.35,34.92 24.26,23.58 15.9,21.79 23.06,0 0,19.2 1.49,35.12 17.11,40 4.08,51.54 l 15.68,1.26 6.9,13.26 -0.5,2.98 3.68,9.05 h 8.07 8.06 L 49.65,69.04 49.15,66.06 56.05,52.8 71.73,51.54 58.7,40 74.32,35.12 Z M 33.33,70.63 c -1.04,0 -1.89,-0.85 -1.89,-1.89 0,-1.04 0.85,-1.89 1.89,-1.89 1.04,0 1.89,0.85 1.89,1.89 0,1.04 -0.84,1.89 -1.89,1.89 z m 9.16,0 c -1.04,0 -1.89,-0.85 -1.89,-1.89 0,-1.04 0.85,-1.89 1.89,-1.89 1.04,0 1.89,0.85 1.89,1.89 0,1.04 -0.85,1.89 -1.89,1.89 z"
  id="path257"
  style="fill:#d60000;fill-opacity:1" />
`,
]
