import i18n from '@/i18n'
// import { metadata } from 'core-js/fn/reflect'

export default [
  {
    component: 'CNavItem',
    name: i18n.global.t('dashboard.title'),
    to: '/dashboard',
    faIcon: 'fas fa-gauge',
    meta: {
      requiredPermissions: [],
    },
  },
  {
    component: 'CNavItem',
    name: i18n.global.t('operations.title'),
    faIcon: 'fas fa-bolt',
    meta: {
      requiredPermissions: ['displaypage_operations'],
    },
    items: [
      {
        component: 'CNavItem',
        name: i18n.global.t('operations-transactions.title'),
        faIcon: 'fas fa-car-bolt',
        to: '/operations/transactions',
        meta: {
          requiredPermissions: [
            'displaypage_operations',
            'displaypage_operations-transactions',
          ],
        },
      },
    ],
  },
  {
    component: 'CNavItem',
    name: i18n.global.t('configuration.title'),
    faIcon: 'fas fa-square-sliders',
    meta: {
      requiredPermissions: ['displaypage_configuration'],
    },
    items: [
      {
        component: 'CNavItem',
        name: i18n.global.t('configuration-chargepoints.title'),
        faIcon: 'fas fa-charging-station',
        to: '/configuration/chargepoints',
        meta: {
          requiredPermissions: [
            'displaypage_configuration',
            'displaypage_configuration-chargepoints',
          ],
        },
      },
      {
        component: 'CNavItem',
        name: i18n.global.t('configuration-idtoken.title'),
        faIcon: 'fas fa-key',
        to: '/configuration/idtoken',
        meta: {
          requiredPermissions: [
            'displaypage_configuration',
            'displaypage_configuration-idtoken',
          ],
        },
      },
      {
        component: 'CNavItem',
        name: i18n.global.t('configuration-vehicles.title'),
        faIcon: 'fas fa-cars',
        to: '/configuration/vehicles',
        meta: {
          requiredPermissions: [
            'displaypage_configuration',
            'displaypage_configuration-vehicles',
          ],
        },
      },
    ],
  },
  {
    component: 'CNavItem',
    name: i18n.global.t('administration.title'),
    faIcon: 'fas fa-screwdriver-wrench',
    meta: {
      requiredPermissions: ['displaypage_administration'],
    },
    items: [
      {
        component: 'CNavItem',
        name: i18n.global.t('administration-users.title'),
        faIcon: 'fas fa-user-tie',
        to: '/administration/users',
        meta: {
          requiredPermissions: [
            'displaypage_administration',
            'displaypage_administration-users',
          ],
        },
      },
      {
        component: 'CNavItem',
        name: i18n.global.t('administration-roles.title'),
        faIcon: 'fas fa-users',
        to: '/administration/roles',
        meta: {
          requiredPermissions: [
            'displaypage_administration',
            'displaypage_administration-roles',
          ],
        },
      },
      {
        component: 'CNavItem',
        name: i18n.global.t('administration-permissions.title'),
        faIcon: 'fas fa-user-lock',
        to: '/administration/permissions',
        meta: {
          requiredPermissions: [
            'displaypage_administration',
            'displaypage_administration-permissions',
          ],
        },
      },
      {
        component: 'CNavItem',
        name: i18n.global.t('administration-properties.title'),
        faIcon: 'fas fa-house',
        to: '/administration/properties',
        meta: {
          requiredPermissions: [
            'displaypage_administration',
            'displaypage_administration-properties',
          ],
        },
      },
    ],
  },
  {
    component: 'CNavItem',
    name: i18n.global.t('settings.title'),
    faIcon: 'fas fa-gears',
    meta: {
      requiredPermissions: [],
    },
    items: [
      {
        component: 'CNavItem',
        name: i18n.global.t('settings-authentication.title'),
        faIcon: 'fas fa-fingerprint',
        to: '/settings/authentication',
        meta: {
          requiredPermissions: [],
        },
      },
    ],
  },
  {
    component: 'CNavItem',
    name: i18n.global.t('logout.title'),
    faIcon: 'fas fa-right-from-bracket',
    to: '/logout',
    meta: {
      requiredPermissions: [],
    },
  },
]
